import * as React from "react";
import { graphql } from "gatsby";
import { Link } from "gatsby-plugin-react-i18next";
import Styled from "styled-components/macro";
import Media from "@uizard-io/styled-media-query";
import Meta from "../../../components/meta";
import Layout from "../../../components/layout/Layout";
import TemplatesSection from "../../../components/pages/templates/TemplatesSection";
import Content from "../../../components/layout/Content/Content";
import Shoutout from "../../../components/pages/signup/Shoutout";

import blogImage from "../../../components/pages/templates/componentTemplates/blog.png";
import cardImage from "../../../components/pages/templates/componentTemplates/card.png";
import dialogImage from "../../../components/pages/templates/componentTemplates/dialog.png";
import footerImage from "../../../components/pages/templates/componentTemplates/footer.png";
import formImage from "../../../components/pages/templates/componentTemplates/form.png";
import galleryImage from "../../../components/pages/templates/componentTemplates/gallery.png";
import headerImage from "../../../components/pages/templates/componentTemplates/header.png";
import listImage from "../../../components/pages/templates/componentTemplates/list.png";
import multimediaImage from "../../../components/pages/templates/componentTemplates/multimedia.png";
import navImage from "../../../components/pages/templates/componentTemplates/nav.png";
import panelImage from "../../../components/pages/templates/componentTemplates/panel.png";
import shopImage from "../../../components/pages/templates/componentTemplates/shop.png";
import socialImage from "../../../components/pages/templates/componentTemplates/social.png";
import tableImage from "../../../components/pages/templates/componentTemplates/table.png";

const ContentExtended = Styled(Content)`
  margin-top: 10rem;

  ${Media.greaterThan("medium")`
    margin-top: 16rem;
  `}
`;

const BreadcrumbContainer = Styled.div`
    margin-top: 10rem;

    ${Media.greaterThan("medium")`
      margin-top: 16rem;
    `}

    margin-bottom: 1rem;
`;

const BreadcrumbLink = Styled(Link)`
  font-size: 1.5rem;

  &:hover {
    color: ${(props) => props.theme.colors.black};
    text-decoration: underline;
  }
`;

const Breadcrumb = Styled.span`
  font-size: 1.5rem;
`;

const Chevron = Styled.span`
  position: relative;
  margin: 0 0.5rem;
  top: 0.35rem;
`;

const Templates = ({ data }) => (
  <Layout>
    <Meta
      title="UI Components Library | Drag & Drop UI Components | Uizard"
      description="Find the perfect UI design components for your next project in Uizard's UI components library. Start a new project or customize a template today."
      url="/templates/component-templates/"
    />

    <ContentExtended narrow>
      <BreadcrumbContainer>
        <BreadcrumbLink to="/templates/">Templates</BreadcrumbLink>
        <Chevron aria-hidden="true">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            viewBox="0 0 16 16"
          >
            <path d="M6.47 4.29l3.54 3.53c.1.1.1.26 0 .36L6.47 11.7a.75.75 0 1 0 1.06 1.06l3.54-3.53c.68-.69.68-1.8 0-2.48L7.53 3.23a.75.75 0 0 0-1.06 1.06z"></path>
          </svg>
        </Chevron>
        <Breadcrumb>Component Templates</Breadcrumb>
      </BreadcrumbContainer>
    </ContentExtended>

    <TemplatesSection
      title="UI components library"
      description="Find the perfect UI components for your next project in our UI components library. Our stunning range of UI design components are here to help you with your digital design. Whether you are starting from scratch or updating one of our pre-made designs, simply drag and drop our UI components and bring your vision to life."
      h1={true}
      thumbnails={[
        {
          image: cardImage,
          alt: "card ui components",
          title: "Card UI components",
          link: "/templates/component-templates/card/",
        },
        {
          image: blogImage,
          alt: "content ui components",
          title: "Content UI components",
          link: "/templates/component-templates/content/",
        },
        {
          image: dialogImage,
          alt: "dialog ui components",
          title: "Dialog UI components",
          link: "/templates/component-templates/dialog/",
        },
        {
          image: footerImage,
          alt: "footer ui components",
          title: "Footer UI components",
          link: "/templates/component-templates/footer/",
        },
        {
          image: formImage,
          alt: "form ui components",
          title: "Form UI components",
          link: "/templates/component-templates/form/",
        },
        {
          image: galleryImage,
          alt: "gallery ui components",
          title: "Gallery UI components",
          link: "/templates/component-templates/gallery/",
        },
        {
          image: headerImage,
          alt: "header ui components",
          title: "Header UI components",
          link: "/templates/component-templates/header/",
        },
        {
          image: listImage,
          alt: "list ui components",
          title: "List UI components",
          link: "/templates/component-templates/list/",
        },
        {
          image: multimediaImage,
          alt: "multimedia ui components",
          title: "Multimedia UI components",
          link: "/templates/component-templates/multimedia/",
        },
        {
          image: navImage,
          alt: "navigation ui components",
          title: "Navigation UI components",
          link: "/templates/component-templates/navigation/",
        },
        {
          image: panelImage,
          alt: "panel ui components",
          title: "Panel UI components",
          link: "/templates/component-templates/panel/",
        },
        {
          image: shopImage,
          alt: "shop ui components",
          title: "Shop UI components",
          link: "/templates/component-templates/shop/",
        },
        {
          image: socialImage,
          alt: "social ui components",
          title: "Social UI components",
          link: "/templates/component-templates/social/",
        },
        {
          image: tableImage,
          alt: "table ui components",
          title: "Table UI components",
          link: "/templates/component-templates/table/",
        },
      ]}
    />

    <Content>
      <Shoutout
        headline="UI design made easy"
        description="No tutorial needed!"
        cta="Sign up for free"
        imageA={data.shoutoutImageA.childImageSharp}
      />
    </Content>
  </Layout>
);

export default Templates;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }

    shoutoutImageA: file(
      relativePath: { eq: "shoutout/shoutout-image-a.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1232, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`;
